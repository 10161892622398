import ValuationModelWrapper from "~/components/valuationModels/Pe/ValuationModelWrapper.vue";
export default {
  __name: 'index',
  setup: function setup(__props) {
    var $route = useRoute();
    return {
      __sfc: true,
      $route: $route,
      ValuationModelWrapper: ValuationModelWrapper
    };
  }
};